import log from './log'

const scripts = {}

export default function addScript(attrs) {
    if (!scripts[attrs.src]) {
        scripts[attrs.src] = new Promise((resolve, reject) => {
            log('adding script', attrs)

            var s = document.createElement('script'),
                el = document.getElementsByTagName('script')[0]

            s.onload = resolve
            s.onerror = reject
            s.async = attrs.async
            s.src = attrs.src
            s.defer = attrs.defer

            el.parentNode.insertBefore(s, el)
        })
    }

    return scripts[attrs.src]
}
