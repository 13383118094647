export default function countWords() {
    try {
        var paras = document.getElementsByTagName('p')

        var texts = []

        for (var i = paras.length - 1; i >= 0; i--) {
            texts.push(paras[i].innerText)
        }

        var s = texts.join(' ')

        s = s.replace(/(^\s*)|(\s*$)/gim, '')
        s = s.replace(/[ ]{2,}/gi, ' ')
        s = s.replace(/\n\s*/g, '\n')

        var tokens = s.split(' ').filter(e => e.length && e !== '\n')

        return tokens.length
    } catch (e) {
        return undefined
    }
}
