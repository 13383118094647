export default slug => {
    function onClick(evt) {
        try {
            const { target } = evt
            const elem = target.closest('[data-engagement-click]')

            if (!elem) {
                return
            }

            const { dataset } = elem

            const { engagementState, engagementClick } = dataset

            const objString = [engagementClick, engagementState].filter(Boolean).join(':')

            return pulse('track', 'trackerEvent', {
                type: 'Engagement',
                action: 'Click',
                object: {
                    type: 'UIElement',
                    '@id': `sdrn:vg:article:${slug}:element:${objString}`,
                },
            })
        } catch (error) {
            console.error('error in click tracking', error)
        }
    }

    document.addEventListener('click', onClick, true)
    return () => document.removeEventListener('click', onClick, true)
}
