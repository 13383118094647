import debug from 'debug'

/*
    In Chrome devtools: make sure log level is set to "Verbose" for this to work
*/

const noop = () => {}

if (process.env.DEBUG && typeof localStorage !== 'undefined') {
    if (process.env.DEBUG) {
        localStorage.debug = [...String(localStorage.debug || '').split(','), 'vg-special-tracking']
            .filter(Boolean)
            .filter((d, i, arr) => arr.indexOf(d) === i)
            .join(',')
    } else {
        localStorage.debug = (localStorage.debug || '')
            .split(',')
            .filter(e => e !== 'vg-special-tracking')
            .join(',')
    }
}

const log = debug('vg-special-tracking')
export default process.env.DEBUG ? log : noop
