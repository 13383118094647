export default function removeUTMTags() {
    const { search } = window.location

    if (!search) {
        return
    }

    const searchQuery = search.substr(1)
    const filteredQuery = searchQuery
        .split('&')
        .filter(part => !part.split('=')[0].startsWith('utm_'))
        .join('&')

    if (filteredQuery !== searchQuery) {
        window.history.replaceState(
            null,
            '',
            window.location.pathname + (filteredQuery ? `?${filteredQuery}` : ''),
        )
    }
}
