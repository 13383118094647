import log from './log'

export default () => {
    const result = {}

    try {
        for (const el of document.querySelectorAll('meta[property*="og:"]')) {
            result[el.getAttribute('property')] = el.getAttribute('content')
        }
    } catch (error) {
        log('error parsing opengraph', error)
    }

    return result
}
