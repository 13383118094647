import log from './log'
import pulse, { loadPulse } from './trackers/pulse'
import mbl from './trackers/mbl'
import getIdentityHelper from './identity-helper'
import delegateClickEvents from './delegate-click-events'
import removeUTMTags from './remove-utm-tags'

const vgSpecialTracking = async (metadata, opts) => {
    opts = opts || {}

    log('called')

    const { showSimplifiedLoginWidget } = getIdentityHelper()
    showSimplifiedLoginWidget();
    async function track(overrideOpts = {}) {
        const mergedOpts = { ...opts, ...overrideOpts }
        log('tracking', metadata, mergedOpts)

        const promises = []

        try {
            promises.push(
                pulse(
                    { ...metadata, articleId: mergedOpts.articleId || metadata.articleId },
                    {
                        promo: mergedOpts.promo,
                        paid: mergedOpts.paid,
                        originUrl: mergedOpts.originUrl,
                        trackLeave: mergedOpts.trackLeave,
                    },
                ),
            )
        } catch (err) {
            console.log(err)
        }

        try {
            promises.push(mbl())
        } catch (err) {
            console.log(err)
        }

        await Promise.all(promises)

        log('removing utm tags')
        removeUTMTags()
    }

    if (opts.track === false) {
        // load pulse sdk (for e.g. salesposter etc) – but do not track or load tcf
        await loadPulse()
    } else {
        await track()
    }

    if (opts.clickListener) {
        log('enabling click listener')
        delegateClickEvents(metadata.articleId)
    }

    return { track }
}

vgSpecialTracking.getIdentityHelper = getIdentityHelper

export default vgSpecialTracking
