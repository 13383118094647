import log from '../log'
import addScript from '../add-script'
import countWords from '../count-words'
import parseOpengraph from '../parse-opengraph'
import getIdentityHelper from '../identity-helper'

export async function loadPulse() {
    log('pulse.load')

    if (!window.pulse) {
        window.pulse =
            window.pulse ||
            function () {
                ;(window.pulse.q = window.pulse.q || []).push(arguments)
            }

        import(/* webpackIgnore: true */ 'https://sdk.pulse.schibsted.com/versioned/3/pulse.min.js')
        import(
            /* webpackIgnore: true */ 'https://sdk.pulse.schibsted.com/plugins/activity-pings/plugin.js'
        )
        import(
            /* webpackIgnore: true */ 'https://sdk.pulse.schibsted.com/plugins/local-history/plugin.js'
        )
        import(
            /* webpackIgnore: true */ 'https://sdk.pulse.schibsted.com/plugins/populate-default-engage-values/plugin.js'
        )
    }
}

export const whenPrerenderingActivated = () =>
  new Promise((resolve) => {
    if (document.prerendering) {
      document.addEventListener("prerenderingchange", () => resolve(), {
        once: true,
      });
    } else {
      resolve();
    }
  });

function loadTcf() {
    // See https://github.schibsted.io/vg/tcf-script-builder
    return addScript({
        src: 'https://tcf.vg.no/vg.js',
        async: true,
    }).then(() => {
        document.dispatchEvent(new Event('cmp:loaded'))
    })
}

async function getTcf() {
    return new Promise((resolve, reject) => {
        if (!('_tcf_' in window)) {
            document.addEventListener('cmp:loaded', () => {
                resolve(window._tcf_)
            })
        } else {
            resolve(window._tcf_)
        }
    })
}

export default async (metadata, opts = {}) => {
    await whenPrerenderingActivated();
    log('pulse.track')

    if (!window.pulse) {
        loadPulse()
    }

    if (!('_tcf_' in window)) {
        loadTcf()
    }

    const og = parseOpengraph()

    const consents = (await getTcf())?.getCachedOrDefaultConsentsForPulse() || {}

    const sdkConfig = {
        useBeacon: true,
        useBeaconWhenAvailable: true,
        altEventHandler: window.pulseEventHandler,
        nativeJwe: window.hermesJwe,
        consents,
        requireAdvertisingOptIn: true, // required when you must support opt-out from targeted advertising
    }

    const type = opts.promo ? 'SalesPoster' : 'Article'

    const evt = {
        object: {
            id: metadata.articleId,
            type,
            category: 'Spesial',
            contentId: metadata.articleId,
            authors: (Array.isArray(metadata.authors) && metadata.authors) || [],
            tags: (Array.isArray(metadata.tags) && metadata.tags) || [],
            custom: {
                'spt:published': metadata.published,
                'spt:updated': metadata.updated,
                'spt:authors': metadata.authors,
                'spt:permalink': metadata.url,
                'spt:wordCount': countWords(),
                'spt:group': metadata.group,
                'spt:imageUrl': og['og:image'],
                'spt:site': 'VG',
                'spt:shareUrl': {
                    facebook: metadata.url,
                    twitter: metadata.url,
                },
                'spt:previewUrl': {
                    http: metadata.url + '?forcedevice=mobile-smartphone',
                    https:
                        String(metadata.url).replace('http:', 'https:') +
                        '?forcedevice=mobile-smartphone',
                },
            },
        },
        provider: {
            productType: 'Web',
            product: 'vg',
            productTag: 'vg',
            'spt:engage': 'VG',
        },
    }

    if (type === 'Article') {
        evt.object.accessLevel = opts.paid ? 'Paid' : 'Free'
    }

    if (opts.originUrl) {
        evt.origin = { url: opts.originUrl }
    }

    log('pulse.event', evt)

    pulse('init', 'vg', sdkConfig, evt)

    const { getUser } = getIdentityHelper()

    pulse('update', {
        actor: getUser({ promo: opts.promo })
            .then(user => {
                if (user) {
                    return {
                        id: user.userId,
                        realm: 'spid.no',
                    }
                }

                return {
                    id: undefined,
                };
            })
            .catch(err => {
                log(err)
                return {
                    id: undefined,
                };
            }),
    })

    pulse('require', 'localHistory')
    pulse('require', 'populateDefaultValues')

    const promise = new Promise((resolve, reject) =>
        pulse(sdk => {
            try {
                console.log('tracking initial page view')
                sdk.trackPageView().then(resolve).catch(reject)
            } catch (error) {
                reject(error)
            }
        }),
    )

    promise.then(() => {
        console.log('tracking initial page view done')
    }).catch(err => {
        console.error('tracking initial page view failed', err)
    });

    pulse(`require`, 'engagementTime')

    if (opts.trackLeave || typeof opts.trackLeave === 'undefined') {
        pulse(sdk => {
            const objectElement =
                opts.trackLeave?.objectElement || document.querySelector('main') || document.body

            const pageElement = opts.trackLeave?.pageElement || document.body

            sdk.addPageLeaveTracking(objectElement, pageElement)
        })
    }

    return promise
}
