import log from '../log'
import addScript from '../add-script'

export default function trackMbl() {
    log('mbl.track')

    addScript({
        src: 'https://log.medietall.no/analytics.js',
        async: true,
        defer: true,
    })
}
